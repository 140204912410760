import { useMutationPatch, useQueryGetAll } from "./abstract";
import axios from "../utils/axios";
import { fastAPIUrl } from "../config";
import { EndUser, PatchEndUserPayload } from "../types/user";
import useAuth from "../hooks/useAuth";

export const useQueryEndUsers = () => {
  const { isAuthenticated, user } = useAuth();

  return useQueryGetAll<EndUser>("end_users", {
    enabled: isAuthenticated && user?.role === "admin",
  });
};

export const useMutationEditEndUser = (id?: string) =>
  useMutationPatch<EndUser, any, PatchEndUserPayload>("end_users", id);

export const useMutationEditCurrentUser = () =>
  useMutationPatch<EndUser, any, PatchEndUserPayload>("users", undefined, {
    mutationFn: (data) =>
      axios.patch(`${fastAPIUrl}/users/me`, data).then(({ data }) => data),
    onSuccessMsg: "Update successful",
    invalidateQueries: {
      queryKey: ["end_users"],
    },
  });
