import React from "react";
import { useRoutes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import * as Sentry from "@sentry/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/GoogleAuthContext";
import { NotificationsProvider } from "./contexts/NotificationsContext";
import { SnackbarProvider } from "notistack";
import ErrorFallback from "./components/ErrorFallback";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import queryString from "query-string";

const queryClient = new QueryClient();

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="Vebu Pulse"
          defaultTitle="Vebu Pulse - Time & Effort Tracking Tool"
        />
        {/* @ts-ignore */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
              <SnackbarProvider maxSnack={6}>
                <NotificationsProvider>
                  <QueryParamProvider
                    adapter={ReactRouter6Adapter}
                    options={{
                      searchStringToObject: queryString.parse,
                      objectToSearchString: queryString.stringify,
                    }}
                  >
                    <QueryClientProvider client={queryClient}>
                      <AuthProvider>{content}</AuthProvider>

                      <ReactQueryDevtools />
                    </QueryClientProvider>
                  </QueryParamProvider>
                </NotificationsProvider>
              </SnackbarProvider>
            </Sentry.ErrorBoundary>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
