import { createContext, ReactNode, useEffect, useReducer } from "react";

import {
  ActionMap,
  AuthState,
  AuthUser,
  GoogleAuthContextType,
} from "../types/auth";
import WaitingForAuthenticationPage from "../components/WaitingForAuthenticationPage";
import {
  getCalendarTimezone,
  getCurrentUser,
  getGoogleAuthUrl,
} from "../utils/api";
import { useNavigate } from "react-router-dom";
import { setSession } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
    timezone: string | null;
  };
  [SIGN_IN]: {
    isAuthenticated: boolean;
    user: AuthUser;
    timezone: string | null;
  };
  [SIGN_OUT]: {
    hasExpiredToken?: boolean;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  timezone: null,
  hasExpiredToken: false,
};

const GoogleAuthReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      console.log("INITIALIZE");
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        timezone: action.payload.timezone,
      };
    case SIGN_IN:
      console.log("SIGN_IN");
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        timezone: action.payload.timezone,
      };
    case SIGN_OUT:
      console.log("SIGN_OUT");
      return {
        ...state,
        isAuthenticated: false,
        hasExpiredToken: action.payload.hasExpiredToken,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<GoogleAuthContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(GoogleAuthReducer, initialState);
  const navigate = useNavigate();

  const initialize = async () => {
    try {
      const { data: user } = await getCurrentUser();

      const fetchTz = async () => {
        const timeZone = await getCalendarTimezone();
        localStorage.setItem(`timezone_${user.id}`, timeZone);
        return timeZone;
      };

      const timezone =
        localStorage.getItem(`timezone_${user.id}`) || (await fetchTz());

      user &&
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
            timezone,
          },
        });
    } catch (err) {
      dispatch({
        type: INITIALIZE,
        payload: { isAuthenticated: false, user: null, timezone: null },
      });
    }
  };

  useEffect(() => {
    setSession(localStorage.getItem("accessToken"));

    initialize();
  }, []);

  const signOut = async (hasExpiredToken?: boolean) => {
    localStorage.removeItem("accessToken");
    dispatch({ type: SIGN_OUT, payload: { hasExpiredToken } });
  };

  const googleSignIn = async () => {
    const { data } = await getGoogleAuthUrl();
    if (data?.authorization_url) {
      window.location.href = data?.authorization_url;
    }
  };

  const authenticate = (user: AuthUser, timezone: string) => {
    dispatch({
      type: SIGN_IN,
      payload: { isAuthenticated: true, user, timezone },
    });
    navigate("/timesheet");
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "google-auth",
        // signIn,
        signOut,
        googleSignIn,
        authenticate,
        initialize,
      }}
    >
      {!state.isInitialized ? <WaitingForAuthenticationPage /> : children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
