import {
  useMutationDelete,
  useMutationPatch,
  useMutationPost,
  useQueryGetAll,
} from "./abstract";
import { PhaseName } from "../types/budgets";
import { useMemo } from "react";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

export const useQueryPhaseNames = () =>
  useQueryGetAll<PhaseName>("phase_names");

export const useGetCustomPhaseNames = () => {
  const { data } = useQueryPhaseNames();

  return useMemo(
    (): PhaseName[] => data?.filter((p) => !p.is_default) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompareMemoize([data])
  );
};

export const useMutationAddPhaseName = () =>
  useMutationPost<PhaseName>("phase_names");

export const useMutationEditPhaseName = (id?: number) =>
  useMutationPatch("phase_names", id);

export const useMutationDeletePhaseName = () =>
  useMutationDelete("phase_names");
