import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import UsersList from "../../components/users/UsersList";
import { MAIN_TEAL_COLOR } from "../../theme/variants";
import { exportCSVFile } from "../../utils/csv";
import { Phase } from "../../types/budgets";
import moment from "moment";
import { useQueryPhases } from "../../api/Phases";
import { useQueryEndUsers } from "../../api/EndUsers";
import { useQueryPortfolioEntities } from "../../api/PortfolioEntities";
import { useQueryPhaseNames } from "../../api/PhaseNames";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Users() {
  const { data: users } = useQueryEndUsers();
  const { data: phases } = useQueryPhases();
  const { data: portfolioEntities } = useQueryPortfolioEntities();
  const { data: phaseNames } = useQueryPhaseNames();

  const exportUserData = () => {
    if (!users) {
      return;
    }

    const headers = {
      id: "Id",
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      country: "Country",
      role: "Role",
      status: "Status",
      assigned_entities: "Assigned Entities",
    };
    const data = users.map(
      ({ id, first_name, last_name, email, country, role, is_active }) => ({
        id,
        first_name,
        last_name,
        email,
        country,
        role,
        status: is_active ? "Active" : "Inactive",
        assigned_entities: `"${phases
          ?.filter((p) => p.phaseusers.some((u) => u.user_id === id))
          .map((p: Phase) => {
            const phaseName = phaseNames?.find(
              ({ id }) => id === p?.phase_name_id
            );
            const portfolioEntity = portfolioEntities?.find(
              ({ id }) => id === p?.portfolio_entity_id
            );

            if (!phaseName || !portfolioEntity) {
              return false;
            }

            return `${portfolioEntity?.portfolio_entity_name} - ${phaseName?.phase_name}`;
          })
          .join(", ")}"`,
      })
    );
    exportCSVFile(
      headers,
      data,
      `Users--${moment().format("YYYY-MM-DD HH:mm:ss")}`
    );
  };

  return (
    <React.Fragment>
      <Box sx={{ position: "relative" }}>
        <Button
          variant="contained"
          onClick={() => exportUserData()}
          sx={{ marginRight: 2, position: "absolute", top: 0, right: 0 }}
        >
          Export raw data to CSV
        </Button>
        <Helmet title="Users" />
        <Typography
          variant="h3"
          gutterBottom
          display="inline"
          sx={{ color: MAIN_TEAL_COLOR }}
        >
          Users
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/Users">
            Manage
          </Link>
          <Typography>Users</Typography>
        </Breadcrumbs>

        <Divider my={6} />

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <UsersList />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Users;
