import {
  useMutationDelete,
  useMutationPatch,
  useMutationPost,
  useQueryAbstract,
} from "./abstract";
import {
  EntriesPayload,
  Entry,
  PatchEntriesPayload,
  PatchEntriesStatusPayload,
} from "../types/entries";
import axiosInstance from "../utils/axios";
import { getDurationInMilli } from "../utils/date";
import { fastAPIUrl } from "../config";
import moment from "moment";
import qs from "qs";

export const useMutationEditEntry = (id?: number) =>
  useMutationPatch<Entry, any, PatchEntriesPayload>("entries", id, {
    onSuccessMsg: "Update successful",
    invalidateQueries: {
      queryKey: ["entries"],
    },
  });

export const useMutationEditEntriesStatus = (isSubmit?: boolean) =>
  useMutationPatch<Entry, any, PatchEntriesStatusPayload>(
    "entries",
    undefined,
    {
      mutationFn: (data) =>
        axiosInstance
          .patch(`${fastAPIUrl}/entries/update_status`, data)
          .then(({ data }) => data),
      onSuccessMsg: isSubmit ? "Timesheet submitted" : "Update successful",
      invalidateQueries: {
        queryKey: ["entries"],
      },
    }
  );

export const useQueryEntriesByApprovalID = (
  approval_id?: number,
  from_all_phases?: boolean
) =>
  useQueryAbstract<Entry[]>({
    enabled: !!approval_id,
    queryKey: ["entries", { approval_id }],
    queryFn: ({ signal }) => {
      return axiosInstance
        .get(
          `${fastAPIUrl}/entries/approval/${approval_id}` +
            qs.stringify({ from_all_phases }, { addQueryPrefix: true }),
          {
            signal,
          }
        )
        .then(({ data }) => data);
    },
  });

export const useQueryEntriesByTimeRange = (
  start_time: string,
  end_time: string,
  user_id?: string,
  status?: string
) =>
  useQueryAbstract<Entry[]>({
    queryKey: ["entries", "filter", start_time, end_time, user_id],
    queryFn: ({ signal }) => {
      if (
        moment(start_time).weekday() !== 0 &&
        getDurationInMilli(start_time, end_time) / 3.6e6 > 24
      )
        return Promise.resolve();
      return axiosInstance
        .get(`${fastAPIUrl}/entries/filter`, {
          signal,
          params: { start_time, end_time, user_id, status },
        })
        .then(({ data }) => data);
    },
  });

export const useMutationDeleteEntry = () => useMutationDelete<Entry>("entries");

export const useMutationAddEntry = () =>
  useMutationPost<EntriesPayload>("entries");
