import {
  useMutationDelete,
  useMutationPatch,
  useMutationPost,
  useQueryGetAll,
} from "./abstract";
import useAuth from "../hooks/useAuth";
import { PhaseUser } from "../types/phaseUser";

export const useQueryPhaseUsers = () => {
  const { user } = useAuth();

  return useQueryGetAll<PhaseUser>("phaseusers", {
    enabled: user?.role === "admin",
  });
};

export const useMutationAddPhaseUser = () =>
  useMutationPost<PhaseUser>("phaseusers");

export const useMutationEditPhaseUser = (id?: number) =>
  useMutationPatch<PhaseUser>("phaseusers", id);

export const useMutationDeletePhaseUser = () => useMutationDelete("phaseusers");
