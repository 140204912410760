import React, { createRef, useState } from "react";
import styled from "@emotion/styled";
import momentTz from "moment-timezone";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { MAIN_TEAL_COLOR } from "../../theme/variants";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  FullCalendarWrapper,
  renderContentHtml,
} from "../../components/calendar/Calendar";
import moment from "moment";
import { computeTotalHours, getDurationInHours } from "../../utils/date";
import useAuth from "../../hooks/useAuth";
import CalendarPopover, {
  initPopoverProps,
  PopoverProps,
} from "../../components/calendar/CalendarPopover";
import { useMapEntriesToCalendarEvents } from "../../utils/mapEntries";
import { ApproveDeclineButtons } from "../../components/approvals/ApprovalsModal";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";
import {
  useGetFilteredApprovals,
  useHandleUpdateApprovals,
  useQueryApprovalByID,
} from "../../api/Approvals";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ViewTimesheet() {
  const calendarRef = createRef<FullCalendar>();
  const { id } = useParams();
  const approvalId = Number(id);
  const { data } = useQueryApprovalByID(approvalId);
  const approval = useGetFilteredApprovals(data ? [data] : [], true)[0];
  const entries = useMapEntriesToCalendarEvents(approval?.entries ?? []);
  const { timezone, user } = useAuth();
  const handleUpdateApproval = useHandleUpdateApprovals();
  const [popoverProps, setPopoverProps] =
    useState<PopoverProps>(initPopoverProps);
  const [loading, setLoading] = useState(false);
  const currDate = momentTz(approval?.start_time).tz(timezone || "");
  const momentMinTime =
    entries.length > 0
      ? moment.min(
          entries.map((e: any) =>
            moment(
              moment().format("YYYY-MM-DD") +
                " " +
                moment(e.start).format("HH:mm:ss")
            )
          )
        )
      : moment();
  const scrollTime =
    entries.length > 0 ? momentMinTime.format("HH") + ":00:00" : undefined;

  const handleSubmit = async (action: string) => {
    setLoading(true);
    approval &&
      (await handleUpdateApproval(
        approval,
        {},
        action,
        entries.map((entry) => Number(entry.id))
      ));
    setLoading(false);
  };

  const handleEventClick = (info: any) => {
    setPopoverProps({
      open: true,
      el: info.el,
      event: info.event,
      duration: getDurationInHours(info.event.start, info.event.end),
    });
  };

  return (
    <React.Fragment>
      <Helmet title="Approvals" />

      <Box sx={{ position: "relative" }}>
        <Typography
          variant="h3"
          gutterBottom
          display="inline"
          sx={{ color: MAIN_TEAL_COLOR }}
        >
          View Timesheet
        </Typography>
        {approval?.status !== "approved" &&
          approval?.entries.some((e) => e.phases?.pm?.id === user?.id) && (
            <Box sx={{ position: "absolute", top: 0, right: 0 }}>
              <ApproveDeclineButtons
                loading={loading}
                handleSubmit={handleSubmit}
                handleDecline={() => handleSubmit("decline")}
                buttonSize="small"
              />
            </Box>
          )}
      </Box>

      <Box sx={{ position: "relative" }}>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/approvals">
            Approvals
          </Link>
          <Typography>
            {approval?.user?.first_name} {approval?.user?.last_name}
          </Typography>
          <Typography>
            {approval?.start_time} - {approval?.end_time}
          </Typography>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Typography>Status: </Typography>
          {approval?.status === "approved" ? (
            <Chip label="Approved" color="success" size="small" />
          ) : approval?.status === "declined" ? (
            <Chip label="Re-opened" color="warning" size="small" />
          ) : (
            <Chip label="Pending" color="default" size="small" />
          )}
        </Box>
      </Box>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FullCalendarWrapper>
            {entries.length > 0 && (
              <FullCalendar
                ref={calendarRef}
                initialView="timeGridWeek"
                initialDate={currDate?.format()}
                titleFormat={{ year: "numeric", day: "numeric", month: "long" }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                events={entries}
                editable={false}
                allDaySlot={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                firstDay={1}
                eventClick={handleEventClick}
                slotDuration={"00:15:00"}
                eventOverlap={false}
                scrollTime={scrollTime}
                eventContent={renderContentHtml}
                headerToolbar={{
                  left: "",
                  center: "",
                  right: "",
                }}
                dayHeaderContent={({ date }) =>
                  `${moment(date).format("dddd")} [${computeTotalHours(
                    entries,
                    date
                  )}]`
                }
              />
            )}
            <CalendarPopover
              popoverProps={popoverProps}
              onClose={() => setPopoverProps(initPopoverProps)}
              handleDeleteClick={(e) => {}}
              handleEditClick={(e) => {}}
              showButtons={false}
            />
          </FullCalendarWrapper>
        </Grid>
      </Grid>
      <LoadingBackdrop open={approval === null} />
    </React.Fragment>
  );
}

export default ViewTimesheet;
