import { useQueryAbstract } from "./abstract";
import axios from "../utils/axios";
import { fastAPIUrl } from "../config";
import { LaborCategory } from "../types/category";
import useAuth from "../hooks/useAuth";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { useMemo } from "react";

export const useQueryCategories = () => {
  const { user } = useAuth();

  return useQueryAbstract<LaborCategory[]>({
    enabled: user?.role === "admin",
    queryKey: ["categories"],
    queryFn: ({ signal }) =>
      axios
        .get(`${fastAPIUrl}/categories/`, { signal })
        .then(({ data }) => data),
  });
};

export const useGetGroupedCategories = () => {
  const { data } = useQueryCategories();

  return useMemo(
    () =>
      (data || []).reduce(
        (map: { [key: string]: LaborCategory[] }, obj: LaborCategory) => {
          const existing = Object.keys(map).find(
            (key) => key === `${obj.team_lookup}`
          );
          if (existing) {
            // @ts-ignore
            map[obj.team_lookup].push(obj);
            return map;
          } else {
            return {
              ...map,
              [obj.team_lookup]: [obj],
            };
          }
        },
        {}
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompareMemoize([data])
  );
};
