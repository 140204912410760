import React, { Dispatch, SetStateAction } from "react";
import moment from "moment";
import { isEqual } from "lodash";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

export type DateRangeType = {
  start?: string;
  end?: string;
};

export type DateRangeFilterType = {
  filterByDateRange?: DateRangeType;
  setFilterByDateRange: Dispatch<SetStateAction<DateRangeType>>;
  setDateRangeNextWeek: () => void;
  setDateRangePrevWeek: () => void;
  setDateRangeThisWeek: () => void;
  isDateRangeThisWeek: boolean;
};

const DateRangeFilterContext = React.createContext<
  DateRangeFilterType | undefined
>(undefined);

function DateRangeFilterProvider({ children }: { children: React.ReactNode }) {
  const defaults = {
    start: moment().startOf("week").format("yyyy-MM-DD HH:mm:ss"),
    end: moment().add(1, "week").startOf("week").format("yyyy-MM-DD HH:mm:ss"),
  };

  const [filterByDateRange, setFilterByDateRange] = useQueryParams(
    {
      start: withDefault(StringParam, defaults.start),
      end: withDefault(StringParam, defaults.end),
    },
    { removeDefaultsFromUrl: true }
  );

  const setDateRangeNextWeek = () => {
    setFilterByDateRange((prevState) => {
      return {
        start: moment(prevState.start)
          .add(1, "week")
          .startOf("week")
          .format("yyyy-MM-DD HH:mm:ss"),
        end: moment(prevState.end)
          .add(1, "week")
          .startOf("week")
          .format("yyyy-MM-DD HH:mm:ss"),
      };
    });
  };

  const setDateRangePrevWeek = () => {
    setFilterByDateRange((prevState) => {
      return {
        start: moment(prevState.start)
          .subtract(1, "week")
          .startOf("week")
          .format("yyyy-MM-DD HH:mm:ss"),
        end: moment(prevState.start).format("yyyy-MM-DD HH:mm:ss"),
      };
    });
  };

  const setDateRangeThisWeek = () => {
    setFilterByDateRange(defaults);
  };

  const isDateRangeThisWeek = isEqual(defaults, filterByDateRange);

  return (
    <DateRangeFilterContext.Provider
      value={{
        filterByDateRange,
        setFilterByDateRange,
        setDateRangeNextWeek,
        setDateRangePrevWeek,
        setDateRangeThisWeek,
        isDateRangeThisWeek,
      }}
    >
      {children}
    </DateRangeFilterContext.Provider>
  );
}

function withDateRangeFilterProvider<P>(
  WrappedComponent: React.ComponentType<P>
) {
  return (props: any) => {
    return (
      <DateRangeFilterProvider>
        <WrappedComponent {...props} />
      </DateRangeFilterProvider>
    );
  };
}

export {
  DateRangeFilterProvider,
  DateRangeFilterContext,
  withDateRangeFilterProvider,
};
