import { fastAPIUrl } from "../config";
import axiosInstance from "../utils/axios";
import { useMutationAbstract } from "./abstract";

export const useGetCalendarEvents = () =>
  useMutationAbstract({
    mutationKey: ["calendar"],
    mutationFn: ({ start, end }: { start: string; end: string }) => {
      return axiosInstance.get(`${fastAPIUrl}/calendar/`, {
        params: { start, end },
      });
    },
  });
