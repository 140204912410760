import { EntryRow } from "../types/entries";
import { useQueryPhases } from "../api/Phases";
import { useQueryWorkstreams } from "../api/Workstreams";
import { useQueryPortfolioEntities } from "../api/PortfolioEntities";
import { useQueryPhaseNames } from "../api/PhaseNames";
import { useIsFetching } from "react-query";
import { useMemo } from "react";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

export const useMapEntriesToCalendarEvents = (entries: EntryRow[]) => {
  const { data: phases } = useQueryPhases();
  const { data: workstreams } = useQueryWorkstreams();
  const { data: portfolioEntities } = useQueryPortfolioEntities();
  const { data: phaseNames } = useQueryPhaseNames();
  const isLoading =
    useIsFetching({
      predicate: (query) => {
        return [
          "phases",
          "workstreams",
          "portfolio_entities",
          "phase_names",
        ].includes(query.queryKey[0] as string);
      },
    }) > 0;

  return useMemo(
    () =>
      entries.map((entry: EntryRow) => {
        const phase = phases?.find(({ id }) => id === entry.phase_id);
        const phaseName = phaseNames?.find(
          ({ id }) => id === phase?.phase_name_id
        );
        const workstream = workstreams?.find(
          ({ id }) => id === entry?.workstream_id
        );
        const portfolioEntity = portfolioEntities?.find(
          ({ id }) => id === phase?.portfolio_entity_id
        );

        return {
          id: `${entry.id}`,
          title: `${portfolioEntity?.portfolio_entity_name} - ${phaseName?.phase_name}`,
          extendedProps: {
            id: entry.id,
            portfolioEntityName: portfolioEntity?.portfolio_entity_name,
            phaseName: phaseName?.phase_name,
            workStream:
              workstream?.work_stream === "_" ? "" : workstream?.work_stream,
            phaseId: entry.phase_id,
            workstreamId: entry.workstream_id,
            category: workstream?.work_stream,
            description: entry.notes,
            start: entry.start_time,
            end: entry.end_time,
            status: entry.status,
            userId: entry.user_id,
            approvalId: entry.approval_id,
            comments: entry.comments,
          },
          start: entry.start_time,
          end: entry.end_time,
          backgroundColor: portfolioEntity?.color_code,
          borderColor: portfolioEntity?.color_code,
        };
      }),
    useDeepCompareMemoize([isLoading, entries])
  );
};
