import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Box, Typography } from "@mui/material";
import VebuPulseLogo from "../../vendor/vebu-pulse-logo-white.png";
import SignInComponent from "../../components/auth/SignIn";
import { MAIN_TEAL_COLOR } from "../../theme/variants";

const Wrapper = styled(Box)`
  padding: ${(props) => props.theme.spacing(6)};
  padding-top: ${(props) => props.theme.spacing(24)};
  width: 100%;
  text-align: center;
  height: 100vh;
  background: ${MAIN_TEAL_COLOR};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
    padding-top: ${(props) => props.theme.spacing(28)};
  }
`;

function SignIn() {
  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Box
        sx={{
          background: MAIN_TEAL_COLOR,
          textAlign: "center",
          paddingTop: "2rem",
          width: "100%",
        }}
      >
        <img
          src={VebuPulseLogo}
          alt="logo"
          style={{
            maxWidth: "318px",
            minHeight: "76px",
          }}
        />
        <Typography
          variant="h6"
          style={{
            fontStyle: "italic",
            color: "white",
            marginTop: 0,
          }}
          align="center"
          mb={8}
        >
          A Time & Effort Tracking Tool
        </Typography>
      </Box>
      <Wrapper>
        <Helmet title="Sign In" />

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
