import axios from "axios";
import { setSession } from "./jwt";

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000,
//   exclude: {
//     methods: ["post", "put", "patch", "delete"],
//   },
//   invalidate: async (config: any, request: any) => {
//     if (request.clearCacheEntry) {
//       await config.store.removeItem(config.uuid);
//     }
//   },
// });

const axiosInstance = axios.create({
  // adapter: cache.adapter,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.status === 401 || error.response?.status === 401) &&
      !window.location.pathname.includes("/auth")
    ) {
      window.location.href = "/auth/sign-in";
      setSession(null);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
