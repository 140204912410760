import React, { useMemo } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { List as ListIcon } from "@mui/icons-material";
import { useQueryEndUsers } from "../../api/EndUsers";
import { DataGrid } from "@mui/x-data-grid";
import { useQueryApprovals } from "../../api/Approvals";
import ApprovalsModal from "./ApprovalsModal";
import { useIsFetching } from "react-query";
import {
  BooleanParam,
  NumberParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

const ApprovalsSubmittedToMe = () => {
  const { data: endUsers } = useQueryEndUsers();
  const { data: approvalsSubmittedToMe } = useQueryApprovals();
  const [showApproved, setShowApproved] = useQueryParam(
    "submitted_show_approved",
    withDefault(BooleanParam, false),
    { removeDefaultsFromUrl: true }
  );
  const [selectedApprovalId, setSelectedApprovalId] = useQueryParam(
    "sel_approval_id",
    NumberParam,
    { removeDefaultsFromUrl: true }
  );
  const [page, setPage] = useQueryParam(
    "submitted_page",
    withDefault(NumberParam, 0),
    {
      removeDefaultsFromUrl: true,
    }
  );

  const isLoading =
    useIsFetching({
      predicate: (query) => {
        return ["end_users", "approvals"].includes(query.queryKey[0] as string);
      },
    }) > 0;

  const approvals = useMemo(() => {
    if (showApproved) {
      return approvalsSubmittedToMe;
    }

    return approvalsSubmittedToMe?.filter(
      (approval) => approval.status !== "approved"
    );
  }, [approvalsSubmittedToMe, showApproved]);

  const columns = [
    {
      field: "email",
      headerName: "User",
      flex: 1,
      renderCell: ({ row }: any) => {
        const User = endUsers?.find((endUser) => endUser.id === row.user_id);
        if (!User) {
          return "N/A";
        }

        return (
          <Stack
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Avatar src={User.profile_pic} />
            <Typography>{`${User.first_name} ${User.last_name}`}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }: any) => {
        return row.status === "approved" ? (
          <Chip label="Approved" color="success" size="small" />
        ) : row.status === "declined" ? (
          <Chip label="Re-opened" color="warning" size="small" />
        ) : (
          <Chip label="Pending" color="default" size="small" />
        );
      },
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      flex: 0.5,
      renderCell: ({ row }: any) => (
        <Button
          startIcon={<ListIcon />}
          sx={{ marginRight: 2 }}
          onClick={() => {
            setSelectedApprovalId(row.id);
          }}
        >
          View Entries
        </Button>
      ),
    },
  ];

  return (
    <>
      <Box mt={16} height="40vh">
        <Stack
          spacing={6}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6" mb={2}>
            Approvals submitted to me
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={!!showApproved}
                onChange={() => setShowApproved(!showApproved)}
              />
            }
            label={`Show Approved (${
              approvalsSubmittedToMe?.filter(
                (approval) => approval.status === "approved"
              ).length ?? 0
            })`}
          />
        </Stack>

        <DataGrid
          rows={approvals ?? []}
          columns={columns}
          pageSize={20}
          loading={isLoading}
          rowsPerPageOptions={[20]}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
        />
      </Box>

      <ApprovalsModal
        approval={approvalsSubmittedToMe?.find(
          ({ id }) => id === selectedApprovalId
        )}
        onClose={() => setSelectedApprovalId(undefined)}
      />
    </>
  );
};

export default ApprovalsSubmittedToMe;
