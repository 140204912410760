import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import Calendar from "../../components/calendar/Calendar";
import { MAIN_TEAL_COLOR } from "../../theme/variants";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Timesheet() {
  return (
    <React.Fragment>
      <Helmet title="Timesheet" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        sx={{ color: MAIN_TEAL_COLOR }}
      >
        Timesheet
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/timesheet">
          Track
        </Link>
        <Typography>Timesheet</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Calendar />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Timesheet;
