import { SidebarItemsType } from "../../types/sidebar";

import {
  Calendar,
  Briefcase,
  User,
  TrendingUp,
  CheckCircle,
} from "react-feather";
import { ADMIN } from "../../constants/roles";

const trackSection = [
  {
    href: "/timesheet",
    icon: Calendar,
    title: "Timesheet",
  },
] as SidebarItemsType[];

const manageSection = [
  {
    href: "/approvals",
    icon: CheckCircle,
    title: "Approvals",
  },
  {
    href: "/portfolio-entities",
    icon: Briefcase,
    title: "Portfolio Entities",
    permissions: [ADMIN],
  },
  {
    href: "/users",
    icon: User,
    title: "Users",
    permissions: [ADMIN],
  },
] as SidebarItemsType[];

const analyzeSection = [
  {
    href: "/reports",
    icon: TrendingUp,
    title: "Reports",
    permissions: [ADMIN],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Track",
    pages: trackSection,
  },
  {
    title: "Manage",
    pages: manageSection,
  },
  {
    title: "Analyze",
    pages: analyzeSection,
  },
];

export default navItems;
