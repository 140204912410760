import { useMemo } from "react";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { Workstream } from "../types/budgets";
import { useQueryGetAll } from "./abstract";

export const useQueryWorkstreams = () =>
  useQueryGetAll<Workstream>("workstreams");

export const useGetBlankWorkstreamID = (): number => {
  const { data } = useQueryWorkstreams();

  return useMemo(
    () => data?.find((w) => w.work_stream === "_")?.id || 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompareMemoize([data])
  );
};
