import moment from "moment";

export const getDurationInMilli = (start: string, end: string) => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  return duration.asMilliseconds();
};

export const getDurationInHours = (start: string, end: string) => {
  return (getDurationInMilli(start, end) / 3.6e6).toFixed(2);
};

export const stringToDateTime = (str: string) =>
  moment(str).format("YYYY-MM-DD HH:mm:ss");

export const stringToDateOnly = (time: moment.Moment) =>
  time.startOf("day").format("YYYY-MM-DD HH:mm:ss");

export const getTotalHours = (arr: any[]) => {
  const totalMins = arr.reduce((accum, obj) => {
    return accum + moment(obj.end).diff(moment(obj.start), "minutes");
  }, 0);
  return totalMins / 60;
};

export const computeTotalHours = (currentEvents: any[], date: Date) => {
  const filtered = currentEvents.filter(
    (ev) =>
      moment(ev.start).format("YYYY-MM-DD") ===
      moment(date).format("YYYY-MM-DD")
  );
  return `${getTotalHours(filtered).toFixed(2)} hours`;
};

export type DateRange = {
  start: string;
  end: string;
};

export const areDatesOverlapping = (dateOne: DateRange, dateTwo: DateRange) => {
  const s1 = new Date(dateOne.start);
  const e1 = new Date(dateOne.end);
  const s2 = new Date(dateTwo.start);
  const e2 = new Date(dateTwo.end);
  return (
    s1.getDate() === s2.getDate() &&
    s1.getTime() < e2.getTime() &&
    e1.getTime() > s2.getTime()
  );
};
