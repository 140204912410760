import { fastAPIUrl } from "../config";
import momentTz from "moment-timezone";
import axios from "./axios";

export const getEntriesCSV = ({
  portfolio_entity_id,
  phase_id,
  start_time,
  end_time,
  user_id,
  status,
}: {
  portfolio_entity_id?: number;
  phase_id?: number;
  start_time: string;
  end_time: string;
  user_id?: string;
  status?: string;
}) => {
  return axios.get(`${fastAPIUrl}/entries/csv`, {
    params: {
      portfolio_entity_id,
      phase_id,
      start_time,
      end_time,
      user_id,
      status,
    },
  });
};

export const getGoogleAuthUrl = () => {
  return axios.get(`${fastAPIUrl}/auth/google/authorize`);
};

export const googleAuthCallback = (search: string) => {
  return axios.get(`${fastAPIUrl}/auth/google/callback${search}`);
};

export const getCurrentUser = () => {
  if (!localStorage.getItem("accessToken")) throw new Error("No token");
  return axios.get(`${fastAPIUrl}/users/me`);
};

export const getCalendarTimezone = async () => {
  const defaultTimezone = momentTz.tz.guess().includes("America")
    ? "America/Los_Angeles"
    : "Asia/Manila";

  const data = await axios
    .get(`${fastAPIUrl}/calendar/timezone`)
    .then(({ data }) => data);

  return data?.timeZone ?? defaultTimezone;
};

export const sendEmail = (message: string, files: any) => {
  const formData = new FormData();
  formData.append("message_body", message);
  files.forEach((v: any) => formData.append("files", v));
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(`${fastAPIUrl}/send_email/`, formData, config);
};

export const getSupersetGuestToken = async () => {
  const { data } = await axios.post(`${fastAPIUrl}/superset/guest_token`);
  return data?.token as string;
};
