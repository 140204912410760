export const parseBudgetCode = (input: string) => {
  const regex = new RegExp(/\[(.*?)\]/, "gm");
  const matches = regex.exec(input);

  if (matches === null) {
    return undefined;
  }

  return matches[1];
};
