import { useNavigate } from "react-router-dom";
import useNotificationsStack from "../hooks/useNotificationsStack";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import axiosMain, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { setSession } from "../utils/jwt";
import { InvalidateQueryFilters } from "react-query/types/core/types";
import { fastAPIUrl } from "../config";
import axios from "../utils/axios";

export function useDefaultErrorHandler() {
  const navigate = useNavigate();
  const { enqueueNotification } = useNotificationsStack();
  const queryClient = useQueryClient();

  const handleAxiosError = (error: AxiosError<{ message?: string }>) => {
    const { response } = error;
    if (!response) {
      console.log("No response");
      return;
    }

    const { status, data } = response;

    const msg = data?.message ?? "Something went wrong.";
    enqueueNotification(msg, {
      variant: "error",
      preventDuplicate: true,
      key: status,
    });

    if (status === 401) {
      debugger;
      setSession(null);
      queryClient.cancelQueries();
      queryClient.clear();
      // TODO: Login redirect
      navigate(
        `/auth/sign-in?redirect=${encodeURIComponent(
          window.location.href.replace(window.location.origin, "")
        )}`
      );
    }
  };

  const handleStockError = (error: Error) => {
    console.log({ error });
    const msg = error?.message ?? "Something went wrong.";
    enqueueNotification(msg, {
      variant: "error",
      preventDuplicate: true,
    });
  };

  return (err: any) => {
    if (axiosMain.isAxiosError(err) && err?.response) {
      handleAxiosError(err as AxiosError<{ message?: string }>);
    } else if (err instanceof Error) {
      handleStockError(err);
    } else {
      enqueueNotification(err.toString(), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };
}

export function useQueryGetAll<T, TError = unknown>(
  key: string,
  options?: UseQueryOptions<T[], TError>
) {
  return useQueryAbstract<T[], TError>({
    queryKey: [key],
    queryFn: ({ signal }) =>
      axios.get(`${fastAPIUrl}/${key}/`, { signal }).then(({ data }) => data),
    ...options,
  });
}

export function useQueryGetOne<T, TError = unknown>(
  key: string,
  id?: string | number,
  options?: UseQueryOptions<T, TError>
) {
  return useQueryAbstract<T, TError>({
    enabled: !!id,
    queryKey: [key, id],
    queryFn: ({ signal }) =>
      axios
        .get(`${fastAPIUrl}/${key}/${id}`, { signal })
        .then(({ data }) => data),
    ...options,
  });
}

export function useMutationPost<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(
  key: string,
  mutationOptions?: MutationOptionsType<TData, TError, TVariables, TContext>
) {
  return useMutationAbstract({
    mutationKey: [key],
    mutationFn: (data) =>
      axios.post(`${fastAPIUrl}/${key}/`, data).then(({ data }) => data),
    onSuccessMsg: "Add successful",
    invalidateQueries: {
      queryKey: [key],
    },
    ...mutationOptions,
  });
}

export function useMutationPatch<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(
  key: string,
  id?: string | number,
  mutationOptions?: MutationOptionsType<TData, TError, TVariables, TContext>
) {
  return useMutationAbstract({
    mutationKey: [key],
    mutationFn: (data) =>
      axios.patch(`${fastAPIUrl}/${key}/${id}`, data).then(({ data }) => data),
    onSuccessMsg: "Update successful",
    invalidateQueries: {
      queryKey: [key],
    },
    ...mutationOptions,
  });
}

export function useMutationDelete<
  TData = unknown,
  TError = unknown,
  TVariables = number | undefined,
  TContext = unknown
>(
  key: string,
  mutationOptions?: MutationOptionsType<TData, TError, TVariables, TContext>
) {
  return useMutationAbstract({
    mutationKey: [key],
    mutationFn: (id) =>
      axios.delete(`${fastAPIUrl}/${key}/${id}`).then(({ data }) => data),
    onSuccessMsg: "Delete successful",
    invalidateQueries: {
      queryKey: [key],
    },
    ...mutationOptions,
  });
}

export function useQueryAbstract<T, TError = unknown>(
  options: UseQueryOptions<T, TError>
): UseQueryResult<T, TError> {
  const opts: UseQueryOptions<T, TError> = {
    retry: false,
    refetchOnWindowFocus: false,
    // refetchInterval: (data, query) => {
    //   if (query.state.dataUpdateCount >= 6) return false;
    //
    //   return query.state.errorUpdateCount <= 6 ? 500 : false;
    // },
    onError: useDefaultErrorHandler(),
    ...options,
  };

  return useQuery<T, TError>(opts);
}

export type MutationOptionsType<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = UseMutationOptions<TData, TError, TVariables, TContext> & {
  invalidateQueries?: InvalidateQueryFilters;
  onSuccessMsg?: string;
};

export function useMutationAbstract<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(mutationOptions: MutationOptionsType<TData, TError, TVariables, TContext>) {
  const { invalidateQueries, onSuccessMsg, ...theRest } = mutationOptions;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<TData, TError, TVariables, TContext>({
    onSuccess: async () => {
      if (invalidateQueries) {
        await queryClient.invalidateQueries(invalidateQueries);
      }
    },
    onError: useDefaultErrorHandler(),
    onSettled: (data, error) => {
      if (!error) {
        enqueueSnackbar(onSuccessMsg ?? "Success", {
          variant: "success",
          preventDuplicate: true,
        });
      }
    },
    ...theRest,
  });
}
