import { useCallback, useMemo } from "react";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { Phase } from "../types/budgets";
import {
  useMutationDelete,
  useMutationPatch,
  useMutationPost,
  useQueryGetAll,
} from "./abstract";
import { chain } from "lodash";
import { useQueryPortfolioEntities } from "./PortfolioEntities";
import { useQueryPhaseNames } from "./PhaseNames";

export const useQueryPhases = () => useQueryGetAll<Phase>("phases");

export const useShouldHideWorkstreams = () => {
  const { data: phases } = useQueryPhases();
  const { data: portfolioEntities } = useQueryPortfolioEntities();
  const { data: phaseNames } = useQueryPhaseNames();

  return useCallback((phaseId: number): boolean => {
    const phase = phases?.find((p) => p.id === phaseId);
    const phaseName = phaseNames?.find(({ id }) => id === phase?.phase_name_id);
    const portfolioEntity = portfolioEntities?.find(
      ({ id }) => id === phase?.portfolio_entity_id
    );

    return (
      portfolioEntity?.portfolio_entity_name === "Vebu Labs" ||
      phaseName?.phase_name === "Strategy"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([phases]));
};

export const useGetGroupPhasesByPortco = (
  selected_id?: number,
  portfolioEntityId?: number,
  userId?: string
) => {
  const { data: phases } = useQueryPhases();
  const { data: portfolioEntities } = useQueryPortfolioEntities();
  const { data: phaseNames } = useQueryPhaseNames();

  return useMemo(() => {
    return chain(phases)
      .map((phase) => {
        const phaseName = phaseNames?.find(
          ({ id }) => id === phase?.phase_name_id
        );
        const portfolioEntity = portfolioEntities?.find(
          ({ id }) => id === phase?.portfolio_entity_id
        );

        return {
          id: phase.id,
          phase_name: phaseName?.phase_name,
          portfolio_entity: portfolioEntity?.portfolio_entity_name,
          portfolio_entity_id: portfolioEntity?.id,
          phaseusers: phase.phaseusers,
        };
      })
      .filter(
        ({ portfolio_entity_id }) =>
          !portfolioEntityId || portfolio_entity_id === portfolioEntityId
      )
      .filter(({ phaseusers }) => {
        return !userId || phaseusers?.some((p) => p.user_id === userId);
      })
      .groupBy("portfolio_entity")
      .value();
  }, useDeepCompareMemoize([phases, portfolioEntities, phaseNames]));
};

export const useMutationAddPhase = () => useMutationPost<Phase>("phases");

export const useMutationEditPhase = (id?: number) =>
  useMutationPatch<Phase, unknown, any>("phases", id);

export const useMutationDeletePhases = () => useMutationDelete("phases");
