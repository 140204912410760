import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  ButtonGroup,
  Divider as MuiDivider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { MAIN_TEAL_COLOR } from "../../theme/variants";
import ApprovalsSubmittedToMe from "../../components/approvals/ApprovalsSubmittedToMe";
import useDateRangeFilter from "../../hooks/useDateRangeFilter";
import { withDateRangeFilterProvider } from "../../contexts/DateRangeFilterContext";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { USER } from "../../constants/roles";
import MySubmittedApprovals from "../../components/approvals/MySubmittedApprovals";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Approvals() {
  const { user } = useAuth();
  const {
    setDateRangeNextWeek,
    setDateRangePrevWeek,
    setDateRangeThisWeek,
    isDateRangeThisWeek,
    filterByDateRange,
  } = useDateRangeFilter();

  return (
    <React.Fragment>
      <Helmet title="Approvals" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        sx={{ color: MAIN_TEAL_COLOR }}
      >
        Approvals
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/approvals">
          Manage
        </Link>
        <Typography>Approvals</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Stack spacing={6} direction="row">
        {!!filterByDateRange && (
          <Typography variant="h4">
            {`${moment(filterByDateRange.start).format("D MMMM")} - ${moment(
              filterByDateRange.end
            )
              .subtract(1, "second")
              .format("D MMMM")}`}
          </Typography>
        )}

        <Button
          variant="contained"
          size="small"
          disabled={isDateRangeThisWeek}
          onClick={() => setDateRangeThisWeek()}
        >
          This Week
        </Button>

        <ButtonGroup disableElevation variant="contained" size="small">
          <Button onClick={() => setDateRangePrevWeek()}>
            <ArrowBackIosNewIcon />
          </Button>
          <Button
            disabled={isDateRangeThisWeek}
            onClick={() => setDateRangeNextWeek()}
          >
            <ArrowForwardIosIcon />
          </Button>
        </ButtonGroup>
      </Stack>

      <Grid container spacing={6} mt={2}>
        <Grid item xs={12}>
          <MySubmittedApprovals />
        </Grid>

        {user?.role !== USER && (
          <Grid item xs={12}>
            <ApprovalsSubmittedToMe />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default withDateRangeFilterProvider(Approvals);
