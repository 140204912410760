import React, { useEffect } from "react";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { lightGreen, orange } from "@mui/material/colors";
import { Approval } from "../../types/approvals";
import FormModal from "../common/FormModal";
import { getDurationInHours } from "../../utils/date";
import { useQueryPhases, useShouldHideWorkstreams } from "../../api/Phases";
import { DataGrid } from "@mui/x-data-grid";
import { useQueryEndUsers } from "../../api/EndUsers";
import { useQueryEntriesByApprovalID } from "../../api/Entries";
import { useQueryPortfolioEntities } from "../../api/PortfolioEntities";
import { useQueryPhaseNames } from "../../api/PhaseNames";
import { useQueryWorkstreams } from "../../api/Workstreams";

export type MyEntriesModalProps = {
  open: boolean;
  onClose: () => void;
  approval?: Approval;
  loading?: boolean;
};

const sortComparator = (v1: number, v2: number) => {
  if (v1 === v2) {
    return 0;
  }

  return v1 > v2 ? 1 : -1;
};

const MyEntriesModal = ({ approval, onClose }: any) => {
  // TODO: Update args type
  const [open, setOpen] = React.useState(!!approval);
  const shouldHideWorkstreams = useShouldHideWorkstreams();
  const { data: endUsers } = useQueryEndUsers();
  const approvalSubmittedByUser = endUsers?.find(
    (endUser) => endUser.id === approval?.user_id
  );

  const { data: entries, isLoading } = useQueryEntriesByApprovalID(
    approval?.id,
    true
  );

  useEffect(() => {
    setOpen(!!approval?.id);
  }, [approval?.id]);

  const { data: phases } = useQueryPhases();
  const { data: portfolioEntities } = useQueryPortfolioEntities();
  const { data: phaseNames } = useQueryPhaseNames();
  const { data: workstreams } = useQueryWorkstreams();

  const columns = [
    {
      field: "entityPhase",
      headerName: "Portfolio Entity - Phase",
      flex: 0.7,
      sortComparator,
      valueGetter: ({ row }: any) => {
        const phase = phases?.find((p) => p.id === row?.phase_id);
        const phaseName = phaseNames?.find(
          ({ id }) => id === phase?.phase_name_id
        );
        const portfolioEntity = portfolioEntities?.find(
          ({ id }) => id === phase?.portfolio_entity_id
        );

        return `${portfolioEntity?.portfolio_entity_name} - ${phaseName?.phase_name}`;
      },
    },
    {
      field: "Workstream",
      headerName: "Workstream",
      flex: 0.7,
      sortComparator,
      renderCell: ({ row }: any) => {
        const workstream = workstreams?.find(
          (p) => p.id === row?.workstream_id
        );
        return shouldHideWorkstreams(row.phase_id) ? (
          <Typography>-</Typography>
        ) : (
          <Typography>{workstream?.work_stream}</Typography>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
      renderCell: ({ row }: any) => (
        <Tooltip title={row.notes}>
          <Typography>{row.notes}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 0.3,
      valueGetter: ({ row }: any) =>
        `${getDurationInHours(row.start_time, row.end_time)} H`,
    },
    {
      field: "comments",
      headerName: "Decline Comment",
      flex: 0.7,
      valueGetter: ({ row }: any) => {
        return row?.comments;
      },
    },
  ];

  return (
    <>
      <FormModal
        title="Timesheet entries"
        description="All your entries for this timesheet"
        open={open}
        onClose={onClose}
        onSubmit={() => {}}
        width={1280}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Typography variant="h4">
            {approvalSubmittedByUser?.first_name}{" "}
            {approvalSubmittedByUser?.last_name}
          </Typography>
          <Typography variant="h4">
            {approval?.start_time} - {approval?.end_time}
          </Typography>
          <Box>
            Status:&nbsp;
            {approval?.status === "approved" ? (
              <Chip label="Approved" color="success" size="small" />
            ) : approval?.status === "declined" ? (
              <Chip label="Re-opened" color="warning" size="small" />
            ) : (
              <Chip label="Pending" color="default" size="small" />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            height: "640px",
            "& .row-approved": {
              bgcolor: () => lightGreen[300],
              "&:hover": {
                bgcolor: () => lightGreen[300],
              },
              "&.Mui-selected": {
                bgcolor: () => lightGreen[400],
                "&:hover": {
                  bgcolor: () => lightGreen[400],
                },
              },
            },
            "& .row-declined": {
              bgcolor: () => orange["A100"],
              "&:hover": {
                bgcolor: () => orange["A100"],
              },
              "&.Mui-selected": {
                bgcolor: () => orange[400],
                "&:hover": {
                  bgcolor: () => orange[400],
                },
              },
            },
          }}
        >
          <DataGrid
            rows={entries ?? []}
            columns={columns}
            pageSize={10}
            loading={isLoading}
            rowsPerPageOptions={[10]}
            getRowClassName={(params) => `row-${params.row.status}`}
          />
        </Box>
      </FormModal>
    </>
  );
};

export default MyEntriesModal;
