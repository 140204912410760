import { useContext } from "react";
import { DateRangeFilterContext } from "../contexts/DateRangeFilterContext";

const useDateRangeFilter = () => {
  const ctx = useContext(DateRangeFilterContext);
  if (!ctx) {
    throw new Error(
      "No DateRangeFilterContext.Provider found when calling useDateRangeFilter."
    );
  }

  return ctx;
};

export default useDateRangeFilter;
