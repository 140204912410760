import React from "react";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// import Blank from "./pages/pages/Blank";
import Timesheet from "./pages/pages/Timesheet";
import Portcos from "./pages/pages/Portcos";
import AuthCallback from "./components/auth/AuthCallback";
import Users from "./pages/pages/Users";
import Reports from "./pages/pages/Reports";
import Approvals from "./pages/pages/Approvals";
import ViewTimesheet from "./pages/pages/ViewTimesheet";
import UserTimesheet from "./pages/pages/UserTimesheet";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Timesheet />,
      },
      {
        path: "/timesheet",
        element: <Timesheet />,
      },
      {
        path: "/portfolio-entities",
        element: <Portcos />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/:id/timesheet",
        element: <UserTimesheet />,
      },
      {
        path: "/approvals",
        element: <Approvals />,
      },
      {
        path: "/approvals/:id",
        element: <ViewTimesheet />,
      },
      {
        path: "/reports",
        element: <Reports />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "callback",
        element: <AuthCallback />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
