import {
  useMutationDelete,
  useMutationPatch,
  useMutationPost,
  useQueryGetAll,
} from "./abstract";
import { PortfolioEntity } from "../types/portcos";
import { useQueryPhases } from "./Phases";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { useMemo } from "react";
import { useQueryPhaseNames } from "./PhaseNames";
import { useQueryEndUsers } from "./EndUsers";
import { useIsFetching } from "react-query";

export const useQueryPortfolioEntities = () =>
  useQueryGetAll<PortfolioEntity>("portfolio_entities");

export const useMutationAddPortfolioEntity = () =>
  useMutationPost<PortfolioEntity>("portfolio_entities");

export const useMutationEditPortfolioEntity = (id?: number) =>
  useMutationPatch<PortfolioEntity>("portfolio_entities", id);

export const useMutationDeletePortfolioEntity = () =>
  useMutationDelete("portfolio_entities", {
    invalidateQueries: {
      predicate: (query) => {
        return ["portfolio_entities", "phases"].includes(
          query.queryKey[0] as string
        );
      },
    },
  });

export const useGetPortfolioEntitiesWithPhasesCodes = () => {
  const { data: portfolioEntities, refetch: refetchPortfolioEntities } =
    useQueryPortfolioEntities();
  const { data: phases, refetch: refetchPhases } = useQueryPhases();
  const { data: phaseNames } = useQueryPhaseNames();
  const { data: endUsers } = useQueryEndUsers();
  const isLoading =
    useIsFetching({
      predicate: (query) => {
        return [
          "phases",
          "portfolio_entities",
          "phase_names",
          "end_users",
        ].includes(query.queryKey[0] as string);
      },
    }) > 0;

  const refetch = () => {
    refetchPortfolioEntities();
    refetchPhases();
  };

  return {
    portcos: useMemo(
      () => {
        if (isLoading) {
          return [];
        }

        return (
          portfolioEntities?.reduce((arr: any[], portco: PortfolioEntity) => {
            return [
              ...arr,
              {
                ...portco,
                phases: phases
                  ?.filter((p) => p.portfolio_entity_id === portco.id)
                  ?.map((phase) => {
                    const phase_name = phaseNames?.find(
                      ({ id }) => id === phase?.phase_name_id
                    );
                    const pm = endUsers?.find(({ id }) => id === phase.user_id);
                    return {
                      ...phase,
                      phase_name,
                      pm,
                    };
                  }),
              },
            ];
          }, []) ?? []
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useDeepCompareMemoize([isLoading])
    ),
    refetch,
  };
};
