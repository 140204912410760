import { atom, useRecoilState } from "recoil";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { useCallback } from "react";

export const NotificationsStackState = atom({
  key: "notifications",
  default: [] as Array<Notification>,
});

export interface Notification {
  key: SnackbarKey;
  message: SnackbarMessage;
  options: OptionsObject;
  dismissed?: boolean;
}

const useNotificationsStack = () => {
  const [notifications, setNotifications] = useRecoilState(
    NotificationsStackState
  );

  const enqueueNotification = useCallback(
    (message: SnackbarMessage, options: OptionsObject) => {
      const { key } = options;

      setNotifications((_notifs) => [
        ...notifications,
        {
          key: key || new Date().getTime() + Math.random(),
          message,
          options,
        },
      ]);
    },
    [notifications, setNotifications]
  );

  const closeNotifications = useCallback(
    (key?: SnackbarKey) => {
      const dismissAll = !key;
      setNotifications((_notifs) =>
        _notifs.map((notification) =>
          dismissAll || notification.key === key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      );
    },
    [setNotifications]
  );

  const removeNotification = useCallback(
    (key: SnackbarKey) => {
      setNotifications((_notifs) =>
        _notifs.filter((notif) => {
          return notif.key !== key;
        })
      );
    },
    [setNotifications]
  );

  return {
    notifications,
    enqueueNotification,
    closeNotifications,
    removeNotification,
  };
};

export default useNotificationsStack;
