import React, { useState } from "react";
import styled from "@emotion/styled";
import { Power, Flag } from "react-feather";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import ReportModal, { ReportSubmitProps } from "../common/ReportModal";
import { sendEmail } from "../../utils/api";
import { useSnackbar } from "notistack";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState<any>(null);
  const { signOut } = useAuth();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSubmit = async ({ message, files }: ReportSubmitProps) => {
    setIsSubmitting(true);
    await sendEmail(message, files);
    setIsSubmitting(false);
    setOpenModal(false);
    enqueueSnackbar("Successfully sent message.", {
      variant: "success",
      preventDuplicate: true,
    });
  };

  return (
    <React.Fragment>
      <Tooltip title="Report">
        <IconButton
          aria-haspopup="true"
          onClick={() => setOpenModal(true)}
          color="inherit"
          size="large"
          sx={{ marginRight: 2 }}
        >
          <Flag />
        </IconButton>
      </Tooltip>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => signOut()}>Sign out</MenuItem>
      </Menu>
      <ReportModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleSubmit}
        loading={isSubmitting}
      />
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
