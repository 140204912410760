import {
  useMutationAbstract,
  useMutationPost,
  useQueryAbstract,
  useQueryGetAll,
  useQueryGetOne,
} from "./abstract";
import axios from "../utils/axios";
import { fastAPIUrl } from "../config";
import useAuth from "../hooks/useAuth";
import { Approval, ApprovalResponse } from "../types/approvals";
import moment from "moment/moment";
import { useQueryClient } from "react-query";
import { useMutationEditEntriesStatus } from "./Entries";
import { PatchEntriesPayload } from "../types/entries";
import { useQueryPhases } from "./Phases";
import useDateRangeFilter from "../hooks/useDateRangeFilter";
import qs from "qs";

moment.locale("en", {
  week: {
    dow: 1,
  },
});

export type ApprovalSubmittedToMeResponse = {
  id: number;
  user_id: string;
  status: string;
};

export const useQueryApprovals = () => {
  const { filterByDateRange } = useDateRangeFilter();
  const key = "approvals";

  return useQueryGetAll<ApprovalSubmittedToMeResponse>(key, {
    queryKey: [key, { filterByDateRange }],
    queryFn: ({ signal }) =>
      axios
        .get(
          `${fastAPIUrl}/${key}/${qs.stringify(
            {
              start_time: filterByDateRange?.start,
              end_time: filterByDateRange?.end,
            },
            {
              addQueryPrefix: true,
            }
          )}`,
          { signal }
        )
        .then(({ data }) => data),
  });
};

export const useQueryApprovalByID = (id?: number) =>
  useQueryGetOne<ApprovalResponse>("approvals", id);

export const useGetFilteredApprovals = (
  approvals: Approval[],
  isOwn?: boolean
) => {
  const { data: phases } = useQueryPhases();
  const { user } = useAuth();

  return approvals.reduce((arr: Approval[], obj: Approval) => {
    const entries = isOwn
      ? obj.entries
      : obj.entries.filter((e) => {
          const phase = phases?.find((phase) => phase.id === e.phase_id);
          if (!phase) {
            return false;
          }

          return phase.user_id === user?.id;
        });

    if (entries.length > 0 && !arr.some((a) => a.id === obj.id)) {
      const first = moment(entries[0].start_time).startOf("week");
      const last = moment(first).add(6, "days");
      const isApproved = entries.every((e) => e.status === "approved");
      const isDeclined = entries.some((e) => e.status === "declined");
      return [
        ...arr,
        {
          ...obj,
          status: isApproved
            ? "approved"
            : isDeclined
            ? "declined"
            : obj.status,
          user: entries[0].User,
          start_time: first.format("MMMM D, YYYY"),
          end_time: last.format("MMMM D, YYYY"),
          entries,
        },
      ];
    }
    return arr;
  }, []);
};

export const useHandleUpdateApprovals = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: patchEntriesStatus } = useMutationEditEntriesStatus();

  return async (
    approval: ApprovalSubmittedToMeResponse,
    editedEntries: { [key: string]: PatchEntriesPayload },
    action: string,
    selectedEntryIds: number[],
    declineMessage?: string
  ) => {
    const status = action === "approve" ? "approved" : "declined";

    await patchEntriesStatus({
      entries: selectedEntryIds.map((entry_id) => {
        return {
          id: entry_id,
          status,
          comments: declineMessage ?? null,
          ...editedEntries[entry_id],
        };
      }),
    });

    // TODO: Test invalidateQueries
    await queryClient.invalidateQueries(["approvals"]);
  };
};

export const useQueryMyApprovals = () => {
  const { filterByDateRange } = useDateRangeFilter();

  return useQueryAbstract<ApprovalSubmittedToMeResponse[]>({
    queryKey: ["approvals", "me", { filterByDateRange }],
    queryFn: ({ signal }) =>
      axios
        .get(
          `${fastAPIUrl}/approvals/me${qs.stringify(
            {
              start_time: filterByDateRange?.start,
              end_time: filterByDateRange?.end,
            },
            {
              addQueryPrefix: true,
            }
          )}`,
          { signal }
        )
        .then(({ data }) => data),
  });
};

export const useMutationAddApproval = () =>
  useMutationPost<ApprovalResponse>("approvals");

/**
 * TODO: Refactor to use useMutationPatch
 */
export const useMutationEditApproval = () => {
  return useMutationAbstract({
    mutationKey: ["approvals"],
    mutationFn: (data: any) => {
      const { id, ...patchData } = data;
      return axios
        .patch(`${fastAPIUrl}/approvals/${id}`, patchData)
        .then(({ data }) => data);
    },
    // onSuccessMsg: "Update successful",
    // invalidateQueries: {
    //   queryKey: [key],
    // },
  });
};
// useMutationPatch("approvals", id);
