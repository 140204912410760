import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import moment from "moment";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ExportToCSVModal, {
  ExportToCSVSubmitProps,
} from "../../components/calendar/ExportToCSVModal";
import { Entry } from "../../types/entries";
import { exportCSVFile } from "../../utils/csv";
import { getEntriesCSV, getSupersetGuestToken } from "../../utils/api";
import { getDurationInMilli } from "../../utils/date";
import { DateRangeFilterProvider } from "../../contexts/DateRangeFilterContext";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import "./Reports.css";
import { supersetDomain, supersetEmbedDashboardID } from "../../config";
import { MAIN_TEAL_COLOR } from "../../theme/variants";
import { useQueryPhases } from "../../api/Phases";
import { useQueryPortfolioEntities } from "../../api/PortfolioEntities";
import { useQueryEndUsers } from "../../api/EndUsers";
import { useQueryPhaseNames } from "../../api/PhaseNames";
import { useQueryWorkstreams } from "../../api/Workstreams";
import { useIsFetching } from "react-query";
import { useQueryTags } from "../../api/Tags";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Reports() {
  const [openExportModal, setOpenExportModal] = useState(false);
  const { data: phases } = useQueryPhases();
  const { data: portcos } = useQueryPortfolioEntities();
  const { data: phaseNames } = useQueryPhaseNames();
  const { data: users } = useQueryEndUsers();
  const { data: workstreams } = useQueryWorkstreams();
  const { data: tags } = useQueryTags();
  const isLoading = useIsFetching() > 0;

  useEffect(() => {
    // @ts-ignore
    embedDashboard({
      id: supersetEmbedDashboardID,
      supersetDomain,
      // @ts-ignore
      mountPoint: document.getElementById("superset-container"),
      fetchGuestToken: getSupersetGuestToken,
      dashboardUiConfig: {
        hideTitle: true,
        hideTab: true,
        hideChartControls: true,
        // @ts-ignore
        filter: {
          expanded: false,
        },
      },
      debug: true,
    });
  }, []);

  const handleSubmitExportModal = async ({
    portfolioEntityId,
    phaseId,
    startDate,
    endDate,
    userId,
  }: ExportToCSVSubmitProps) => {
    const _portfolioEntity = portcos?.find((p) => p.id === portfolioEntityId);
    const _phase = phases?.find((p) => p.id === phaseId);
    const _phaseName = phaseNames?.find(
      ({ id }) => id === _phase?.phase_name_id
    );
    const _user = users?.find((p) => p.id === userId);
    const { data } = await getEntriesCSV({
      portfolio_entity_id: portfolioEntityId,
      phase_id: phaseId,
      start_time: startDate,
      end_time: endDate,
      user_id: userId !== "" ? userId : undefined,
      // status: "approved",
    });
    const headers = {
      day: "Day",
      user: "User",
      budget_code: "Budget Code",
      time: "Time (Hours)",
      timestamp: "Timestamp",
      notes: "Notes",
      portfolio_entity: "Portfolio Entity",
      phase: "Phase",
      workstream: "Workstream",
      tags: "Tags",
    };

    const filteredData = data.map(
      ({
        start_time,
        end_time,
        notes,
        tags: tagIdsOnly,
        phase_id,
        user_id,
        workstream_id,
      }: Entry & { tags?: { id: number }[] }) => {
        const phase = phases?.find((p) => p.id === phase_id);
        const portfolioEntity = portcos?.find(
          (p) => p.id === phase?.portfolio_entity_id
        );
        const phaseName = phaseNames?.find(
          ({ id }) => id === phase?.phase_name_id
        );
        const user = users?.find((p) => p.id === user_id);
        const workstream = workstreams?.find(({ id }) => id === workstream_id);
        const tagIds = tagIdsOnly?.map(({ id }) => id);
        const tagsLabel = tags
          ?.filter((tag) => tagIds?.includes(tag.id))
          ?.map((t: any) => t.label)
          ?.join(", ");

        return {
          day: moment(start_time).format("MM/DD/YY"),
          user: `${user?.first_name} ${user?.last_name}`,
          budget_code: `${portfolioEntity?.portfolio_entity_name} - ${phaseName?.phase_name}`,
          time: (getDurationInMilli(start_time, end_time) / 3.6e6).toFixed(2),
          timestamp: `${moment(start_time).format("hh:mm a")}-${moment(
            end_time
          ).format("hh:mm a")}`,
          notes,
          portfolio_entity: portfolioEntity?.portfolio_entity_name,
          phase: phaseName?.phase_name,
          workstream: workstream?.work_stream,
          tags: tagsLabel,
        };
      }
    );
    exportCSVFile(
      headers,
      filteredData,
      `Report--${moment(startDate).format("YYYY-MM-DD")}-${moment(
        endDate
      ).format("YYYY-MM-DD")}${
        _phase
          ? `--${_portfolioEntity?.portfolio_entity_name}-${_phaseName?.phase_name}`
          : _portfolioEntity
          ? `--${_portfolioEntity?.portfolio_entity_name}`
          : ""
      }${_user ? `--${_user.first_name}-${_user.last_name}` : ""}`
    );
    setOpenExportModal(false);
  };

  return (
    <DateRangeFilterProvider>
      <Box sx={{ position: "relative" }}>
        <Button
          variant="contained"
          onClick={() => setOpenExportModal(true)}
          sx={{ marginRight: 2, position: "absolute", top: 0, right: 0 }}
          disabled={isLoading}
        >
          Export raw data to CSV
        </Button>

        <Helmet>
          <title>Reports</title>
          <meta
            httpEquiv="Content-Security-Policy"
            content="upgrade-insecure-requests"
          />
        </Helmet>
        <Typography
          variant="h3"
          gutterBottom
          display="inline"
          sx={{ color: MAIN_TEAL_COLOR }}
        >
          Reports
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/reports">
            Manage
          </Link>
          <Typography>Reports</Typography>
        </Breadcrumbs>

        <Divider my={6} />

        {/* <Grid container spacing={6}>
        <DateRangeStartEndPicker />

        <Grid item xs={12} lg={6}>
          <Button
            variant="contained"
            onClick={() => setOpenExportModal(true)}
            sx={{ marginRight: 2, float: "right" }}
          >
            Export raw data to CSV
          </Button>
        </Grid>
      </Grid> */}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div id="superset-container"></div>
          </Grid>
        </Grid>
        <ExportToCSVModal
          open={openExportModal}
          onClose={() => setOpenExportModal(false)}
          onSubmit={handleSubmitExportModal}
        />
      </Box>
    </DateRangeFilterProvider>
  );
}

export default Reports;
